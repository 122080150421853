// custom styles for the site
html {
    height: 100%;
}

body {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    .usa-header {
        z-index: 70009;
    }
}

article {
    a:not(.usa-button) {
        color: $uhc-blue!important;
        text-decoration: underline!important;
        transition: $global-transition;
        &:hover {
            color: lighten($uhc-blue, 5%)!important;
            text-decoration: none!important;
        }
    }
    form input {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 6px;
    }
}

footer.page-footer {
    margin-top: auto;
}

aside.post-sidebar .custom-html-widget>div.housecall, aside.course-sidebar .custom-html-widget>div.housecall, .sidebar-card .custom-html-widget>div.housecall {
    background: $uhc-blue!important;
    position: relative;
    padding-top: 2.5em!important;
    padding-bottom: 2em!important;
    border-radius: 6px 6px 0 0;
    &:before {
        content: "";
        position: absolute;
        left: -4.2em;
        bottom: 0;
        width: 32.5em;
        background-image: url('/wp-content/themes/uhc-uswds/img/wave-blobs.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 115%;
        opacity: .45;
        z-index: 0;
    }
    &:after {
        content: "";
        position: absolute;
        top: 2.3em;
        right: 1.25em;
        width: 115px;
        height: 55px;
        background-image: url('/wp-content/themes/uhc-uswds/img/animated-email-icon022120_2.gif');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .lead {
        font-family: $helvetica-condensed;
        font-size: em(42);
        line-height: 1;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0!important;
        z-index: 1;
        position: relative;
        text-align: left!important;
        span:not(.subline) {
            padding-right: calc(1.75em + 1vw);
            display: block;
        }
        span.subline {
            color: #eea904;
            font-weight: 400;
            text-transform: initial;
            display: block;
            line-height: 1.1;
            margin-top: .15em;
            font-size: 80%;
        }
    }
    @media (max-width: 768px) {
        padding-top: 2.25em!important;
        &:before {
            width: 28em;
        }
        &:after {
            width: 100px;
            top: 1.8em;
        }
        .lead {
            font-size: calc(#{em(30)} + 1vw);
        }
    }
    @media (max-width: $breakpoint-phone) {
        padding: 2em 1em 1.5em 1em!important;
        &:after {
            width: 100px;
            top: 1.6em;
            right: 0.75em;
        }
    }
}
aside.course-sidebar .custom-html-widget>div.housecall {
    padding: 1.75em!important;
}
.sidebar-widget #gform_1 {
    padding: 1em 1.5em 1.5em!important;
    label.gfield_label {
        margin-top: 0.5em!important;
    }
    label.gfield_consent_label {
        font-size: 13.15px!important;
    }
    @media (min-width: 768px) {
        li#field_1_4 {
            width: 70%!important;
        }

        li#field_1_7 {
            width: 30%!important;
        }
    }
    @media (max-width: $breakpoint-phone) {
        padding: 0.5em 1em 1em!important;
    }
}

.gform_confirmation_wrapper {
    padding: 1.25em;
}

// HouseCall Form Styles
div#gform_wrapper_1 {
    margin: 0;
    // margin-bottom: 1.5em;
    form#gform_1 {
        padding: 2em;
        padding-top: 0;
        li.gfield {
            margin-top: 0;
            margin-bottom: 0;
            label.gfield_label, label.gfield_consent_label {
                margin-top: 1em;
            }
        }
        input {
            margin-top: 0;
        }
        #input_1_3 {
            margin-top: 0!important;
            margin-bottom: 3px;
        }
        input#gform_submit_button_1 {
            font-family: $helvetica-condensed!important;
            line-height: 1!important;
            font-size: 28px!important;
            height: auto!important;
            padding: 1.5rem 2rem!important;
            margin-bottom: 0;
            display: block;
            width: 100%!important;
            max-width: 100%!important;
            text-transform: uppercase;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2)!important;
            &:hover {
                box-shadow: 0 3px 10px transparentize($uhc-gold, 0.5)!important;
            }
        }
        .ginput_container input {
            border-radius: 6px;
            border: 1px solid #b5b5b5;
            padding: 5px 12px;
            transition: $global-transition;
            &:focus, &:active {
                border-color: #7f7f7f;
            }
        }
        span.gfield_required {
            color: $uhc-gold;
        }
        li#field_1_4, li#field_1_7 {
            @media (min-width: 768px) {
                width: 50%;
                display: inline-grid;
                span.address_zip {
                    width: 100%;
                }
            }
        }
        label.gfield_consent_label {
            font-size: em(14);
        }
        .gform_footer {
            padding-bottom: 0;
        }
        div.ginput_recaptcha {
            margin-top: 1.5em;
        }
        div#gform_confirmation_wrapper_1 {
            margin: 2em;
            line-height: 1.5;
        }
    }
}

.privacy-policy-field .gfield_description {
    border: none;
    overflow: hidden;
    margin-top: 0!important;
    padding-left: 0;
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

aside#info {
    padding-bottom: 0;
    margin-bottom: 2em;
    box-shadow: none!important;
    z-index: 1;
    .aside-container {
        max-width: 450px;
        margin: 0 auto;
        box-shadow: $box-shadow-large;
    }
    a.my-chart {
        display: block;
        color: #fff;
        text-decoration: none;
        font-weight: 700;
        text-align: center;
        font-size: 2.5em;
        padding: .5em 2em;
        transition: .2s all;
        border-radius: 6px;
        margin-top: 0.5em;
        margin-bottom: 0;
        //top: -0.75em;
        position: relative;
    }
    &.home-sidebar {
        border: 1px solid rgba(0, 0, 0, 0.2);
        .sidebar-card {
            margin-bottom: 0;
            & > .sidebar-widget {
                &:first-of-type {
                    img {
                        position: relative;
                        top: -4em;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                        margin-bottom: -3em;
                    }
                }
                h3 {
                    font-family: $helvetica;
                    font-weight: bold;
                    color: $uhc-blue;
                    font-size: 2em!important;
                    line-height: 1.1;
                    span {
                        color: $uhc-gold;
                        font-family: $helvetica-condensed;
                        font-weight: normal;
                        font-size: 0.85em;
                        display: block;
                        line-height: 1.1;
                        margin-top: 0.2em;
                    }
                }
                p {
                    color: $uhc-blue;
                }
                a.usa-button {
                    width: 100%;
                    padding: 0.7em 1em;
                    font-family: $helvetica-condensed!important;
                    font-weight: bold;
                    font-size: 1.45em;
                    text-transform: uppercase;
                }
            }
        }
    }
    // @media (max-width: 1200px) {
    //     box-shadow: none!important;
    // }
    @media (max-width: 1200px) {
        display: none;
    }
}

// homepage styles
.home {
    .page-heading {
        .container {
            max-width: 1600px;
            h1 {
                text-transform: initial;
                color: $uhc-gold;
                max-width: 600px!important;
                text-shadow: 2px 2px 12px transparentize($uhc-blue, 0.5)!important;
                margin-bottom: 0.25em!important;
                strong {
                    text-transform: uppercase;
                    color: white;
                    font-weight: bold!important;
                    font-size: 1.5em!important;
                    line-height: 0.65!important;
                }
            }
            @media (max-width: 650px) {
                h1 {
                    font-size: 1.8em;
                }
                p strong {
                    font-size: 1em;
                }
                .flex-button-container {
                    .usa-button {
                        font-size: 1em;
                    }
                }
            }
        }
    }

    .logos {
        padding: 25px 0px 40px;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $medium-screen) {
            padding: 15px 0px;
        }
        .col {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            flex: 1 0 auto;
        }
        img {
            @media (max-width: #{ $medium-screen - 1}) {
                align-self: center !important;
            }
        }
    }
}

div#update-notice {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    text-align: center;
    & > div {
        padding: 1.75em 2em;
        padding-bottom: 2em;
        background-color: transparentize(white, 0.1)!important;
        position: relative;
        max-width: 95%;
        overflow: hidden;
        box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.75);
        border-radius: 2px;
        & > a.close {
            position: absolute;
            top: -0.15em;
            right: 0.4em;
            cursor: pointer;
            color: $uhc-blue;
            transition: $global-transition;
            &:hover {
                color: transparentize($uhc-blue, 0.2);
            }
        }
        p {
            font-size: 20px;
            margin-bottom: 8px;
        }
        .usa-button {
            margin: 1em auto 0 auto;
            &:hover {
                background-color: lighten($uhc-gold, 3%);
            }
        }
        @media (min-width: 600px) {
            max-width: 80%;
            padding: 2.25em 3.25em;
        }
        @media (min-width: 1200px) {
            max-width: 750px;
        }
    }
    h3 {
        font-family: 'Helvetica Neue', Helvetica;
        display: block;
        line-height: 1;
        color: $uhc-blue;
        font-size: 38px;
    }
    .resp-container {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
    }
    .resp-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

div#class-completed {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 10px;
    text-align: center;
    & > div {
        padding: 1.75em 2em;
        padding-bottom: 2em;
        background-color: white!important;
        position: relative;
        max-width: 95%;
        // overflow: hidden;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        background-image: url('https://wvobgyncenter.com/wp-content/uploads/2021/06/modal-curve.svg');
        background-position: center top;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        .footer-links-container {
            display: flex;
            flex-wrap: wrap;
            & > div {
                display: flex;
                flex: 1;
                align-items: center;
                img {
                    max-height: 85px;
                    height: 100%;
                    width: auto;
                    margin: 1em;
                    margin-left: 1.5em;
                }
                a {
                    text-align: left;
                    line-height: 1.3;
                    &:hover {
                        text-decoration: none;
                    }
                }
                &:first-of-type img {
                    margin-left: 0;
                    max-height: 100px;
                }
            }
            @media (max-width: 768px) {
                flex-direction: column;
                & > div {
                    a {
                        font-size: 1.2em;
                    }
                }
            }
        }
        & > a.close {
            position: absolute;
            top: 1em;
            right: 1em;
            cursor: pointer;
            width: 2em;
            height: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #A0BBFE;
            border-radius: 50%;
            color: white;
            text-decoration: none;
            transition: $global-transition;
            font-size: 1.25em;
            i {
                top: -1px;
                position: relative;
            }
            &:hover {
                background-color: darken(#A0BBFE, 5%);
            }
        }
        p {
            font-size: 20px;
            margin-bottom: 8px;
            color: $uhc-blue;
            max-width: 475px;
            line-height: 1.3;
            &.check-email {
                font-size: 95%;
                margin-top: 0;
            }
            &.share-experience {
                color: $uhc-gold;
                font-weight: bold;
                margin-bottom: 0;
                margin-top: 1em;
                font-size: 1.25em;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    border-top: 4px dotted $uhc-gold;
                    left: 1em;
                    right: 1em;
                    top: -0.75em;
                }
            }
        }
        .usa-button {
            margin: 1em auto 0 auto;
            &:hover {
                background-color: lighten($uhc-gold, 3%);
            }
        }
        @media (min-width: 600px) {
            max-width: 80%;
            padding: 2.25em 3.25em;
        }
        @media (max-width: 768px) {
            p.check-email {
                font-size: 100%;
                margin-top: 0.25em;
            }
            .footer-links-container {
                & > div img {
                    max-width: 100px;
                    width: 100%;
                    height: auto;
                    margin-left: 0;
                }
            }
        }
        @media (min-width: 1200px) {
            max-width: 750px;
        }
    }
}

.loader-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999999999;
    display: flex;
    align-items: center;
    opacity: 0;
    justify-content: center;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 10px;
    text-align: center;
    transition: $global-transition;
    .lesson-loader {
        border: 12px solid $uhc-blue;
        border-top: 12px solid $uhc-gold;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.8);
        width: 100px;
        height: 100px;
        animation: lessonSpin 1.5s linear infinite;
    }
    .saving-text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $uhc-blue;
        font-size: 0.9em;
    }
    &.shown {
        opacity: 1;
        pointer-events: all;
    }
}

@keyframes lessonSpin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.tsparticles-canvas-el {
    background-color: rgba(255, 255, 255, 0.5);
}

.hero-swiper {
    background-image: none;
    padding: 0!important;
    height: 30vw;
    height: calc(275px + 25vw);
    max-height: 1000px;
    .swiper-wrapper {
        z-index: initial;
        .swiper-slide {
            display: flex;
            align-items: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            overflow: hidden;
            .container {
                width: 100%;
            }
        }
    }
    @media (min-width: 768px) {
        height: calc(300px + 30vw);
        .swiper-wrapper {
            .swiper-slide {
                .container {
                    margin-bottom: 3vw;
                }
            }
        }
    }
}

.svg-grid {
    position: absolute;
    background-image: url('/wp-content/themes/uhc-uswds/img/svg-grid.svg');
    top: -5%;
    bottom: 0;
    left: -24%;
    right: 18%;
    opacity: .25;
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
}

.courses-row {
    display: flex;
    // align-items: center;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
    .course-card {
        margin: 0.5em 1em;
        padding: 1.25em;
        max-width: 475px;
        display: flex;
        align-items: stretch;
        flex-direction: column;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        flex: 1;
        height: 100%;
        div.course-card-icon-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            img.course-card-icon {
                height: 220px;
                margin: -3em auto .95em auto;
            }
        }
        p.course-card-title {
            margin: 0.25em 0 0.4em;
            font-size: 1.85em;
            line-height: 1.2;
            padding-top: 1em;
            padding: 0 0.2em;
            position: relative;
            color: $uhc-blue;
            text-align: center;
            font-family: $iowan-old;
            &.class-title {
                &:after {
                    content: "NOW ONLINE";
                    position: absolute;
                    left: 0.25em;
                    right: 0.25em;
                    top: -1.15em;
                    font-family: $helvetica-condensed;
                    font-weight: bold;
                    font-size: 0.45em;
                    color: rgba(0, 0, 0, 0.4);
                }
            }
        }
        div.course-card-description {
            p {
                margin: 0;
                font-size: 0.95em;
            }
        }
        .course-card-buttons {
            margin-top: auto;
            .continue-course-button + .usa-button.gold, .continue-course-button ~ p.login {
                display: none;
            }
            a.usa-button {
                padding: 0.5em 1.5em;
                margin-bottom: 0;
                width: 100%;
                text-transform: uppercase;
                font-family: $helvetica-condensed!important;
                font-weight: bold;
                font-size: 1.25em;
            }
            p.login {
                font-size: 0.8em;
                text-align: center;
                margin: 14px 0 6px;
                line-height: 1;
            }
        }
    }

    @media (max-width: 992px) {
        & > div {
            margin: 1.25em 0;
            .course-card {
                margin: 0.5em auto;
            }
        }
    }
}

.course-sidebar {
    background-color: transparent;
    margin-top: 2em!important;
    box-shadow: $box-shadow-large;
    .aside-container {
        border-radius: 8px!important;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .sidebar-card {
        padding: 1.25em;
        border-radius: 8px;
        box-shadow: none!important;
        p {
            margin: 0;
        }
        &.intro-card {
            margin-bottom: 0;
            p {
                font-size: 1.75em;
                font-family: $helvetica-condensed;
                display: block;
                text-align: center;
                color: $uhc-blue;
            }
            a.usa-button {
                width: 100%;
                padding: 0.75em 1.25em;
            }
        }
    }
    &.time-to-register {
        .aside-container {
            border-color: $uhc-gold;
            animation: 2.5s ease-in-out infinite timeToRegister;
        }
    }
}

@keyframes timeToRegister {
    0% {
        border-color: lighten($uhc-gold, 15%);
        box-shadow: 0 10px 40px transparentize($uhc-gold, 0.9);
    }
    45% {
        border-color: $uhc-gold;
        box-shadow: 0 10px 20px transparentize($uhc-gold, 0.8);
    }
    55% {
        border-color: $uhc-gold;
        box-shadow: 0 10px 20px transparentize($uhc-gold, 0.8);
    }
    100% {
        border-color: lighten($uhc-gold, 15%);
        box-shadow: 0 10px 40px transparentize($uhc-gold, 0.9);
    }
}

table.pmpro_table {
    thead {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        th {
            background-color: transparent;
        }
    }
    td, th {
        border: none;
    }
    td:not(:last-of-type) {
        border-right: 1px dotted rgba(0, 0, 0, 0.2);
    }
    tr:not(:last-of-type) {
        td {
            border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
        }
    }
    a.pmpro_btn {
        background-color: $uhc-gold;
        color: white!important;
        text-decoration: none!important;
        font-family: $helvetica-condensed;
        border: none;
        box-shadow: 0 0 12px transparentize($uhc-gold, 0.9);
        transition: $global-transition;
        &:hover {
            background-color: lighten($uhc-gold, 5%);
            color: white!important;
        }
    }
}

#pmpro_account-profile {
    ul {
        @extend .styled;
        margin: 1em 0;
    }
}

.page-heading.course {
    h1 {
        font-weight: bold!important;
    }
}

div.card-content {
    display: flex!important;
    flex-wrap: nowrap!important;
    align-items: stretch!important;
    max-width: 750px;
    padding: 1.25em 1.5em;
    margin: 2em 0 2.5em;
    border-radius: 6px;
    & > div.image {
        flex: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100px;
            margin-right: 20px;
        }
    }
    & > div {
        flex: 4;
        display: inline-flex;
        align-items: center;
    }
    a:link {
        transition: $global-transition;
        color: inherit!important;
        filter: brightness(1);
        &:hover {
            text-decoration: none;
            filter: brightness(1.35);
        }
    }
    @media (max-width: 450px) {
        flex-direction: column;
    }
}

.iframe-container {
    overflow: hidden;
    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;
}

.iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.home-video-section {
    background-image: url('/wp-content/uploads/2020/08/video-background.svg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
    padding-top: calc(10vw + 8em);
    margin-top: calc(-10vw - 8em);
    padding-bottom: 2em;
    .video-row {
        display: flex;
        align-items: center;
        .iframe-container {
            box-shadow: 1px 4px 12px transparentize($uhc-blue, 0.8);
            border-radius: 6px;
        }
        .video-content {
            padding: 1em 1em 1em 1.5em;
            h3 {
                font-family: $iowan-old;
                color: $uhc-blue;
            }
        }
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
}

.default-page-heading {
    padding: calc(6vw + 2.5em) 0 calc(7.5vw + 2.5em);
    .container {
        h1 {
            text-transform: initial;
            color: $uhc-gold;
            max-width: 600px!important;
            text-shadow: 2px 2px 12px transparentize($uhc-blue, 0.5)!important;
            margin-bottom: 0.25em!important;
            strong {
                text-transform: uppercase;
                color: white;
                font-weight: bold!important;
                font-size: 1.5em!important;
                line-height: 0.65!important;
            }
        }
        h1.no-topline {
            max-width: 600px!important;
            text-shadow: 2px 2px 12px transparentize($uhc-blue, 0.5)!important;
            margin-bottom: 0.25em!important;
            font-weight: bold!important;
            font-size: em(60)!important;
            text-transform: uppercase;
            letter-spacing: -1px!important;
            color: white!important;
        }
    }
}

.class-page-heading.page-heading,
.class-intro-page-heading.page-heading {
    padding: 1vw 0;
    background-color: #ADBCFF!important;
    position: relative;
    overflow: hidden;
    .container {
        h1 {
            margin-bottom: 0;
            position: relative;
            padding-left: 2.35em;
            height: calc(1vw + 3em);
            display: flex;
            text-shadow: 0px 1px 10px transparentize(darken(#adbcff, 15%), 0.25);
            // align-items: center;
            font-family: $helvetica;
            max-width: 800px;
            text-transform: none;
            flex-direction: column;
            justify-content: center;
            letter-spacing: -1.25px;
            color: black;
            & > span {
                font-size: 0.5em;
                letter-spacing: 0;
                margin-bottom: 0.2em;
                color: white;
                & > span.welcome {
                    text-transform: uppercase;
                    font-family: $helvetica;
                    font-weight: bold;
                }
            }
            &.breastfeeding-education {
                &:before {
                    content: "";
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;
                    background-image: url('/wp-content/uploads/2020/08/Breast-Feeding-Class_ico.png');
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 2em;
                }
            }
            &.childbirth {
                &:before {
                    content: "";
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;
                    background-image: url('/wp-content/uploads/2020/08/Child-Birth-Class_ico.png');
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 2em;
                }
            }
        }
    }
    nav.member-nav {
        position: absolute;
        top: 0;
        right: -1em;
        z-index: 2;
        & > div {
            background-color: $uhc-blue;
            transform: skew(-33.75deg,0deg);
            position: relative;
            z-index: 1;
            ul {
                list-style: none;
                display: flex;
                margin: 0;
                padding-right: 1em;
                transform: skew(33.75deg,0deg);
                li {
                    margin: 0 0.25em;
                    a {
                        color: rgba(255, 255, 255, 1);
                        padding: 0.75em;
                        display: block;
                        text-transform: uppercase;
                        transition: $global-transition;
                        &:hover {
                            text-decoration: none;
                            background-color: lighten($uhc-blue, 8%);
                        }
                    }
                }
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: -0.75em;
                width: 0.75em;
                z-index: 0;
                background-color: $uhc-gold;
            }
        }
        @media (max-width: 1110px) {
            display: none;
        }
    }
}

.classes-page-heading {
    padding: 1vw 0;
    background-color: #ADBCFF!important;
    position: relative;
    overflow: hidden;
    .container {
        h1 {
            margin-bottom: 0;
            position: relative;
            height: calc(1vw + 3em);
            display: flex;
            text-shadow: 0px 1px 10px transparentize(darken(#adbcff, 15%), 0.25);
            font-family: $helvetica;
            max-width: 100%;
            text-align: center;
            text-transform: none;
            flex-direction: column;
            justify-content: center;
            letter-spacing: -1.25px;
            color: black;
            & > span {
                font-size: 0.5em;
                letter-spacing: 0;
                margin-bottom: 0.2em;
                color: white;
                & > span.welcome {
                    text-transform: uppercase;
                    font-family: $helvetica;
                    font-weight: bold;
                }
            }
        }
    }
}

.usa-button.register-button {
    font-family: $helvetica-condensed!important;
    font-size: 28px!important;
    height: initial!important;
    padding: 1.5rem 2rem!important;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2)!important;
    &:hover {
        box-shadow: 0 3px 10px transparentize($uhc-gold, 0.5)!important;
    }
}

.class-intro-page-heading.page-heading {
    padding: 1vw 0;
}
form.pmpro_form {
    h2 {
        font-weight: normal!important;
        font-size: 2.25em;
        color: $uhc-gold;
        letter-spacing: -1px;
        text-transform: initial;
        text-align: center;
        margin-bottom: 0.15em;
    }
    .pmpro_checkout-fields {
        .pmpro_checkout-field {
            margin-top: 0;
            margin-bottom: 0;
            label {
                margin-top: 0.7em;
                color: $uhc-blue;
            }
        }
        input, select {
            margin-top: 0;
            border-radius: 6px;
            border: 1px solid #b5b5b5;
            padding: 5px 12px;
            max-width: 100%;
            transition: $global-transition;
            &:focus, &:active {
                border-color: #7f7f7f;
            }
        }
        select {
            background-image: none,url(./dist/img/arrow-down.svg),url(./dist/img/arrow-down.png);
        }
        .pmpro_btn-submit-checkout {
            font-family: $helvetica-condensed!important;
            background-color: $uhc-gold;
            line-height: 1!important;
            font-size: 28px!important;
            height: auto!important;
            padding: 1.5rem 2rem!important;
            border: none!important;
            margin-top: 0.75em;
            margin-bottom: 0;
            display: block;
            width: 100%!important;
            max-width: 100%!important;
            text-transform: uppercase;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2)!important;
            &:hover {
                box-shadow: 0 3px 10px transparentize($uhc-gold, 0.5)!important;
            }
        }
        .login-link {
            margin-bottom: 0;
            a {
                color: $uhc-blue;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.course-nav-container {
    background-color: #F4ECEC;
    nav.course-nav {
        ul {
            list-style: none;
            display: flex;
            padding: 0;
            margin: 0;
            li {
                margin-right: 0.25em;
                margin-bottom: 0;
                padding-right: 0.25em;
                position: relative;
                a {
                    padding: 0.5em 0.75em;
                    display: block;
                    text-decoration: none;
                    transition: $global-transition;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.3);
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0.5em;
                    bottom: 0.5em;
                    width: 1px;
                    background-color: rgba(0, 0, 0, 0.3);
                }
                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }
                &[disabled] {
                    pointer-events: none;
                    a {
                        opacity: 0.25;
                    }
                }
            }
            @media (min-width: 425px) and (max-width: 800px) {
                // flex-direction: column;
                flex-wrap: wrap;
                li {
                    padding: 0;
                    margin: 0;
                    flex-basis: 50%;
                    a {
                        text-align: center;
                    }
                    &:nth-of-type(even) {
                        border-left: 1px solid rgba(0, 0, 0, 0.15);
                    }
                    &:nth-of-type(n + 3) {
                        border-top: 1px solid rgba(0, 0, 0, 0.15);
                    }
                    &:after {
                        display: none;
                    }
                }
            }
            @media (max-width: 450px) {
                flex-direction: column;
                li {
                    a {
                        text-align: center;
                    }
                    &:nth-of-type(n + 2) {
                        border-top: 1px solid rgba(0, 0, 0, 0.15);
                    }
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

// .lesson-card {
//     border-radius: 6px;
//     border: 1px solid #ADBCFF;
//     margin: 0 auto 1em auto;
//     max-width: 725px;
//     .lesson-card-header {
//         background-color: $uhc-blue;
//         border-radius: 5px 5px 0 0;
//         padding: 1em 2em;
//         h2 {
//             margin: 0;
//             color: white;
//             font-size: 1.5em;
//             letter-spacing: 0;
//             padding-left: 1.5em;
//             font-family: $helvetica;
//             text-transform: initial!important;
//             position: relative;
//         }
//     }
//     .lesson-card-content {
//         padding: 1em 2em;
//         p {
//             margin: 0;
//         }
//     }
//     .lesson-card-buttons {
//         padding: 0 2em 1.5em;
//         .usa-button {
//             padding: 0.7em 1.25em;
//             padding-left: 2.5em;
//             background-color: white;
//             border: 1px solid $uhc-blue;
//             color: $uhc-blue;
//             text-transform: uppercase;
//             position: relative;
//             font-weight: bold;
//             font-size: 0.9em;
//             box-shadow: 0 2px 14px transparentize($uhc-blue, 0.875);
//             transition: $global-transition;
//             &.watch, &.download {
//                 &:before {
//                     position: absolute;
//                     left: 0.85em;
//                     top: 0;
//                     bottom: 0;
//                     width: 1.35em;
//                     content: "";
//                     background-size: 95%;
//                     background-repeat: no-repeat;
//                     background-position: center center;
//                     transition: $global-transition;
//                 }
//             }
//             &.watch {
//                 &:before {
//                     background-image: url('/wp-content/uploads/2020/09/play-icon.svg');
//                 }
//             }
//             &.download {
//                 &:before {
//                     background-image: url('/wp-content/uploads/2020/09/download-icon.svg');
//                 }
//             }
//             &:hover {
//                 border-color: lighten($uhc-blue, 10%);
//                 color: lighten($uhc-blue, 8%);
//                 box-shadow: 0 2px 18px transparentize($uhc-blue, 0.95);
//                 &:before {
//                     filter: brightness(1.08);
//                 }
//             }
//             &:active, &:focus {
//                 box-shadow: 0 2px 18px transparentize($uhc-blue, 1);
//             }
//         }
//     }
//     &[disabled] {
//         position: relative;
//         z-index: 1;
//         // filter: grayscale(1);
//         opacity: 0.5;
//         & > * {
//             pointer-events: none;
//         }
//         &:before {
//             content: url('/wp-content/uploads/2020/09/lock-icon-white.svg');
//             position: absolute;
//             margin: auto;
//             z-index: 3;
//             width: 5em;
//             top: 0;
//             bottom: 0;
//             left: 0;
//             right: 0;
//             display: flex;
//             align-items: center;
//             filter: drop-shadow(0px 1px 22px transparentize(darken($uhc-blue, 5%), 0.15));
//         }
//         &:after {
//             content: "";
//             position: absolute;
//             top: 0;
//             bottom: 0;
//             left: 0;
//             right: 0;
//             z-index: 2;
//             border-radius: 5px;
//             background: linear-gradient(to top, transparentize($uhc-blue, 0.2), transparentize($uhc-blue, 0.5));
//             background-position: center center!important;
//         }
//     }
// }

ul.pmpro_series_list {
    border: none;
    & > li {
        display: flex;
        flex-direction: column;
        padding: 0;
        transform: translate(0)!important;
        background-color: white;
        border-radius: 6px;
        border: 1px solid #ADBCFF;
        margin: 0 auto 1em auto;
        max-width: 725px;
        position: relative;
        z-index: 1;
        box-shadow: none!important;
        grid-gap: 0;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            box-shadow: $box-shadow-large;
        }
        span {
            padding: 1em;
            width: 100%;
        }
        .lesson-card-content {
            padding: 1em 2em 0;
            p:first-of-type {
                margin-top: 0;
            }
            ul.styled {
                margin-bottom: 0;
                li {
                    background: none;
                    padding-top: 0;
                    padding-right: 0;
                }
            }
        }
        p:empty {
            display: none;
        }
        span.pmpro_series_item-title {
            background-color: $uhc-blue;
            border-radius: 5px 5px 0 0;
            padding: 0.9em 2em 0.9em 2.75em;
            color: white;
            font-size: 1.5em;
            position: relative;
            font-weight: bold;
            &:before {
                position: absolute;
                left: 1em;
                top: 0;
                bottom: 0;
                width: 1.15em;
                content: "";
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
            &.week-1 {
                &:before {
                    background-image: url('/wp-content/uploads/2020/09/week-1.svg');
                }
            }
            &.week-2 {
                &:before {
                    background-image: url('/wp-content/uploads/2020/09/week-2.svg');
                }
            }
            &.week-3 {
                &:before {
                    background-image: url('/wp-content/uploads/2020/09/week-3.svg');
                }
            }
            &.week-4 {
                &:before {
                    background-image: url('/wp-content/uploads/2020/09/week-4.svg');
                }
            }
            a {
                margin: 0;
                color: white;
                letter-spacing: 0;
                font-family: $helvetica;
                text-transform: initial!important;
                position: relative;
                text-decoration: none;
                display: block;
                line-height: 1.1;
            }
        }
        // p {
        //     padding: 1em 2.5em 0 1.5em;
        //     width: 100%;
        //     margin: 0;
        // }
        span:not(.pmpro_series_item-title) {
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid rgba(0, 0, 100, 0.05);
            background-color: rgba(0, 0, 75, 0.025);
            margin-top: 1em;
            padding: 0.5em 1em;
            a.pmpro_btn-primary {
                padding: 0.7em 1.25em 0.7em 2.75em;
                margin: 0.5em;
                background-color: white;
                border: 1px solid $uhc-blue;
                color: $uhc-blue;
                text-transform: uppercase;
                position: relative;
                font-weight: bold;
                font-size: 0.9em;
                box-shadow: 0 2px 14px transparentize($uhc-blue, 0.875);
                transition: $global-transition;
                &.watch, &.download {
                    &:before {
                        position: absolute;
                        left: 0.85em;
                        top: 0;
                        bottom: 0;
                        width: 1.35em;
                        content: "";
                        background-size: 95%;
                        background-repeat: no-repeat;
                        background-position: center center;
                        transition: $global-transition;
                    }
                }
                &.watch {
                    &:before {
                        background-image: url('/wp-content/uploads/2020/09/play-icon.svg');
                    }
                }
                &.download {
                    &:before {
                        background-image: url('/wp-content/uploads/2020/09/download-icon.svg');
                    }
                }
                &:hover {
                    border-color: lighten($uhc-blue, 10%);
                    color: lighten($uhc-blue, 8%);
                    box-shadow: 0 2px 18px transparentize($uhc-blue, 0.95);
                    &:before {
                        filter: brightness(1.08);
                    }
                }
                &:active, &:focus {
                    box-shadow: 0 2px 18px transparentize($uhc-blue, 1);
                }
            }
        }
        &.unavailable {
            position: relative;
            z-index: 1;
            // filter: grayscale(1);
            opacity: 0.5;
            & > * {
                pointer-events: none;
            }
            &:before {
                content: url('/wp-content/uploads/2020/09/lock-icon-white.svg');
                position: absolute;
                margin: auto;
                z-index: 3;
                width: 3em;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                filter: drop-shadow(0px 1px 22px transparentize(darken($uhc-blue, 5%), 0.15));
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 2;
                border-radius: 5px;
                background: linear-gradient(to top, transparentize($uhc-blue, 0.2), transparentize($uhc-blue, 0.5));
                background-position: center center!important;
            }
            span.pmpro_series_item-unavailable {
                color: white;
                z-index: 3;
            }
        }
    }
}

.lesson-title {
    padding: 1em 0;
    .container {
        display: flex;
        align-items: center;
        .back-button {
            background-color: transparent;
            color: rgba(0, 0, 0, 0.25);
            font-size: 2em;
            margin: 0 0.5em 0 0;
            vertical-align: middle;
            height: 100%;
            width: auto;
            padding: 0.25em;
            text-decoration: none;
            font-family: Helvetica, sans-serif;
            transition: $global-transition;
            &:hover {
                color: rgba(0, 0, 0, 0.4);
            }
        }
        h2 {
            margin: 0;
            font-family: $helvetica;
            color: $uhc-blue;
            text-transform: initial;
            font-size: 2em;
            letter-spacing: 0;
            line-height: 1.1;
        }
    }
}

.lesson-title, .lesson-card-header {
    h2 {
        position: relative;
        padding-left: 1.5em;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 1em;
            content: "";
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
        &.week-1 {
            &:before {
                background-image: url('/wp-content/uploads/2020/09/week-1.svg');
            }
        }
        &.week-2 {
            &:before {
                background-image: url('/wp-content/uploads/2020/09/week-2.svg');
            }
        }
        &.week-3 {
            &:before {
                background-image: url('/wp-content/uploads/2020/09/week-3.svg');
            }
        }
        &.week-4 {
            &:before {
                background-image: url('/wp-content/uploads/2020/09/week-4.svg');
            }
        }
    }
}

.lesson-content-container{
    background-color: #F4ECEC;
    & > .container {
        padding-bottom: 5%;
        max-width: 1150px;
    }
    .lesson-navigation-buttons {
        display: flex;
        justify-content: space-between;
        div {
            display: flex;
            a {
                pointer-events: none;
                background-color: rgba(255, 255, 255, 1)!important;
                color: $uhc-blue!important;
                padding: 1em 2em;
                margin-left: 0;
                margin-right: 0;
                display: flex;
                align-items: center;
                border-radius: 8px;
                box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1), 0 0 0px 0px transparentize($uhc-blue, 1);
                transition: $global-transition;
                z-index: 1;
                opacity: 0.35;
                &[href] {
                    pointer-events: all;
                    opacity: 1;
                }
                i {
                    font-size: 0.65em;
                    margin-left: 0;
                    margin-right: 0.5em;
                    transition: $global-transition;
                }
                &:hover {
                    background-color: rgba(252, 252, 253, 1)!important;
                    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1), 0 0 0px 1px transparentize($uhc-blue, 0.25);
                    i {
                        transform: translateX(-3px);
                    }
                }
            }
            &.lesson-navigation-buttons_next {
                justify-content: flex-end;
                a {
                    i {
                        margin-left: 0.5em;
                        margin-right: 0;
                    }
                    &:hover {
                        i {
                            transform: translateX(3px);
                        }
                    }
                }
            }
        }
    }
}

.lesson-downloads-container, .lesson-survey-container  {
    & > .container {
        max-width: 1150px;
        padding: 3em 0;
        .row {
            // gap: 2em;
            display: flex;
            flex-wrap: wrap;
            // & > div {
            //     flex: 1;
            // }
        }
        ul.styled {
            li {
                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        h3 {
            margin: 0;
            font-family: $helvetica;
            color: $uhc-blue;
            font-size: 1.75em!important;
        }
        @media (max-width: 1296px) {
            padding: 3em 16px;
        }
        // @media (max-width: 768px) {
        //     .row > div {
        //         flex-basis: 100%;
        //     }
        // }
    }
    // #downloads {
    //     margin-bottom: 2em;
    //     padding-bottom: 2em;
    //     border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    // }
    #survey {
        #gform_2 {
            max-height: 190px;
            transition: 1s ease-in-out;
            overflow: hidden;
            .gfield:first-of-type {
                margin-top: 8px;
            }
            &.open {
                max-height: 1000em;
            }
            #gform_submit_button_2 {
                line-height: 1;
            }
        }
        #gform_wrapper_2 {
            margin-top: 0;
            margin-bottom: 0;
        }
        a.view-full-survey {
            position: relative;
            border-radius: 0 0 6px 6px;
            background-color: $uhc-blue;
            color: white;
            text-decoration: none;
            font-size: 0.85em;
            text-align: center;
            width: 100%;
            display: block;
            padding: 0.15em;
            box-shadow: 0 -8px 10px -8px rgba(0, 0, 0, 0.25), 0 -24px 8px 6px rgba(255, 255, 255, 0.75);
            transition: $global-transition;
            &:hover {
                background-color: lighten($uhc-blue, 8%);
            }
        }
    }
}

#gform_2, #gform_3 {
    #gform_fields_3 {
        & > li {
            margin: 0;
            &:first-of-type > label {
                margin: 0;
            }
            .gsurvey-rating-wrapper {
                margin-top: 0;
            }
        }
    }
    .gsurvey-rating {
        [type=radio]+label {
            width: 2.25em;
            background-size: 2em 2em;
            margin: 0.5em 0;
            &::before {
                background: none;
                box-shadow: none;
                width: 2em;
                height: 2em;
            }
        }
    }
    .gfield_label {
        margin-top: 1em;
    }
    .ginput_container_radio {
        margin-top: 0.5em;
    }
    textarea {
        max-width: 100%;
    }
}

#gform_3 {
    ul#gform_fields_3 {
        & > li {
            max-width: 500px;
        }
    }
}

.lesson-sidebar-widget ~ .intro-card {
    display: none;
}

section.course-resources {
    h2 {
        color: $uhc-blue;
    }
}

ul.downloads-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    padding-bottom: 2em;
    margin-bottom: 2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    li {
        flex-basis: 33.3333%;
        max-width: 400px;
        margin-bottom: 0.5em;
        a {
            border-radius: 10px;
            // box-shadow: $box-shadow-large;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0.5em;
            padding: 1em;
            height: 100%;
            font-size: 1.05em;
            border: 1px solid $uhc-blue;
            text-decoration: none;
            line-height: 1.25;
            font-weight: bold;
            transition: $global-transition;
            &:hover {
                color: lighten($uhc-blue, 8%);
                border-color: lighten($uhc-blue, 8%);
            }
            &:focus, &:active {
                color: $uhc-gold;
                border-color: $uhc-gold;
            }
        }
        @media (max-width: 992px) {
            flex-basis: 50%;
        }
        @media (max-width: 768px) {
            flex-basis: 100%;
        }
    }
}

.video-lesson-card {
    border-radius: 0 0 6px 6px;
    .video-lesson-content {
        padding: 2em;
        padding-bottom: 1.5em;
        @media (max-width: 992px) {
            padding: 1.5em;
        }
        p {
            margin: 0;
            strong {
                color: $uhc-blue;
            }
        }
        .video-lesson-content-container {
            display: flex;
            .video-lesson-summary {
                flex: 5;
                margin-right: 1em;
            }
            .progress-bar-box {
                margin-left: 1em;
                margin-bottom: 1em;
                flex: 3;
                .progress-bar-container {
                    display: flex;
                    align-items: center;
                    height: fit-content;
                    padding-bottom: 1em;
                    border-bottom: 1px dashed rgba(0, 0, 0, 0.25);
                    margin-bottom: 1em;
                    #video_progress {
                        appearance: none;
                        &::-webkit-progress-bar {
                            // background-color: rgba(0, 0, 0, 0.5);
                            background-color: rgba(0, 0, 0, 0.05);
                            box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
                            border-radius: 6px;
                        }
                        &::-webkit-progress-value {
                            background-color: #adbcff;
                            background: linear-gradient(60deg, #4759ab, #adbcff);
                            border-radius: 6px;
                        }
                    }
                    #video_progress_percent {
                        margin: 0;
                        margin-left: 0.5em!important;
                        font-family: $helvetica-condensed;
                        color: $uhc-blue;
                        min-width: 45px;
                    }
                }
                #video-chapters-container {
                    position: relative;
                    max-height: 300px;
                    overflow-y: scroll;
                    padding-bottom: 2px;
                    padding-right: 5px;
                    background: /* Shadow covers */
                    linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
                    radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0)) 0 100%;
                    background: /* Shadow covers */
                    linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
                    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0)) 0 100%;
                    background-repeat: no-repeat;
                    background-color: white;
                    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
                    /* Opera doesn't support this in the shorthand */
                    background-attachment: local, local, scroll, scroll;
                    .chapters-title {
                        margin-bottom: 0.25em;
                    }
                    a.chapter-link {
                        display: block;
                        line-height: 1.25;
                        color: $uhc-blue;
                        padding: 0.5em 0.25em;
                        text-decoration: none;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                        display: flex;
                        strong {
                            min-width: 2em;
                        }
                        &:last-of-type {
                            border: none;
                        }
                        &:hover {
                            background-color: transparentize($uhc-blue, 0.98);
                        }
                        &.active {
                            background-color: transparentize($uhc-gold, 0.95);
                            color: rgba(0, 0, 0, 0.6);
                            border-right: 0.25em solid $uhc-gold;
                        }
                    }
                    &::-webkit-scrollbar {
                        width: 0.75em;
                    }
                    &::-webkit-scrollbar-track {
                        background-color: rgba(255, 255, 255, 0.9);
                        -webkit-box-shadow: inset 0 0 8px rgba(0,0,0,0.1);
                        border-radius: 4px;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 4px;
                        background-color: $uhc-blue;
                        background: linear-gradient(60%, $uhc-blue, lighten($uhc-blue, 8%));
                        cursor: pointer;
                    }
                }
            }
            @media (max-width: 992px) {
                flex-direction: column-reverse;
                .progress-bar-box {
                    margin-left: 0;
                }
            }
        }
        .video-lesson-content-links {
            margin-top: 1em;
            display: flex;
            justify-content: space-between;
            a {
                text-transform: uppercase;
                padding-left: 2em;
                position: relative;
                font-weight: bold;
                &:before {
                    content: "";
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 1.5em;

                }
                &.transcript {
                    font-family: $helvetica-condensed;
                    color: $uhc-blue;
                    &:before {
                        background-image: url('/wp-content/uploads/2020/09/transcript-icon.svg');
                    }
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .zoom-info-container {
        padding: 2em;
        border-top: 1px solid rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: flex-end;
        & > div {
            padding: 0 0.5em;
            img {
                margin: auto 0.5em;
            }
            p, a {
                margin: auto 0.5em;
                line-height: 1.1;
                font-family: $helvetica-condensed;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        .zoom-logo-title {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
            p {
                font-size: 1.1em;
            }
        }
        .zoom-date {
            display: flex;
            min-width: 165px;
            border-left: 1px dashed rgba(0, 0, 0, 0.25);
            border-right: 1px dashed rgba(0, 0, 0, 0.25);
            p {
                color: $uhc-blue;
            }
        }
        .zoom-link {
            padding-right: 0;
            min-width: 200px;
            display: flex;
            justify-content: center;
            a {
                display: flex;
                flex: 1;
                justify-content: center;
                color: $uhc-blue;
                padding: 0.5em;
                margin-right: 0;
                background-color: white;
                border-radius: 8px;
                box-shadow: 0 0 0px 0px transparentize($uhc-blue, 1);
                transition: $global-transition;
                &:hover {
                    text-decoration: none;
                    background-color: transparentize($uhc-blue, 0.99);
                    box-shadow: 0 0 0px 1px transparentize($uhc-blue, 0.25);
                }
            }
        }
        @media (max-width: 768px) {
            padding: 1.5em 1em;
            flex-wrap: wrap;
            & > div {
                flex-basis: 100%;
            }
            .zoom-logo-title {
                padding-bottom: 2em;
                justify-content: flex-start;
            }
            .zoom-date {
                border-left: none;
                flex-basis: 40%;
            }
            .zoom-link {
                flex-basis: 60%;
            }
        }
    }
}

.video-transcript {
    margin: 2em 0;
    padding: 0.5em 2em;
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    display: none;
    max-height: 18em;
    overflow-y: scroll;
    background: /* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
    radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
    p {
        strong {
            font-size: 0.9em;
        }
        margin-bottom: 0.75em!important;
    }
    &::-webkit-scrollbar {
        width: 0.75em;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.9);
        -webkit-box-shadow: inset 0 0 8px rgba(0,0,0,0.1);
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $uhc-blue;
        background: linear-gradient(60%, $uhc-blue, lighten($uhc-blue, 8%));
        cursor: pointer;
    }
}

/* PMPro Styles */

.pmpro_checkout-h3-name {
    font-family: $helvetica-condensed;
    font-weight: bold;
    text-transform: uppercase;
    color: $uhc-blue;
    font-size: 1.5em;
}

#pmpro_mmpu_groups {
    margin-right: 2em;
    .pmpro_mmpu_group {
        border: none!important;
        .pmpro_level-name {
            color: $uhc-blue;
        }
        .pmpro_mmpu_level {
            background-color: transparentize($uhc-gold, 0.95)!important;
            border: none!important;
        }
    }
}

.pmpro_mmpu_group-name {
    color: $uhc-blue;
}

.levelnametext {
    font-size: 1.25em;
    font-family: $helvetica;
    font-weight: bold;
    color: $uhc-blue;
    height: 100px;
    display: flex;
    align-items: center;
    position: relative;
    &.breastfeeding-education {
        padding-left: 5.25em;
        &:before {
            content: "";
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-image: url('/wp-content/uploads/2020/08/Breast-Feeding-Class_ico.png');
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 5em;
        }
    }
    &.childbirth {
        padding-left: 5.25em;
        &:before {
            content: "";
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-image: url('/wp-content/uploads/2020/08/Child-Birth-Class_ico.png');
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 5em;
        }
    }
}

#pmpro_mmpu_level_selections .widget {
    border: none;
    box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 6px!important;
    .widget-title {
        background-color: $uhc-blue!important;
        color: white;
        border: none;
        border-radius: 6px 6px 0 0;
    }
    .mmpu_addedlevels, .mmpu_removedlevels {
        margin-top: 0.5em;
        label {
            margin-top: 0.5em;
        }
    }
}

.usa-button.blue:disabled {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    opacity: 0.5;
}

.faq {
    margin-bottom: 1em;
    .q {
        font-size: 1.25em;
        color: $uhc-blue;
        letter-spacing: 0;
        line-height: 1!important;
    }
}

.flex-button-container {
    display: flex;
    flex-wrap: wrap;
    a:first-of-type {
        margin-right: 0.5em;
    }
}

.usa-button.gold.outline {
    background: transparent!important;
    border-width: 2px;
    border-style: solid;
    border-color: $uhc-gold;
}

#loginform {
    .login-remember {
        margin-bottom: 0;
        label {
            display: flex;
            align-items: center;
            input[type="checkbox"] {
                appearance: none;
                position: relative;
                left: 0;
                width: 1.2em;
                height: 1.2em;
                margin-right: 0.75em;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.2em;
                &:checked {
                    background-color: $uhc-blue;
                    &:before {
                        // content: "\2713";
                        content: "";
                        transform: rotate(45deg) scale(0.6);
                        transform-origin: center;
                        height: 1.05em;
                        width: 0.5em;
                        border-bottom: 0.25em solid white;
                        border-right: 0.25em solid white;
                        bottom: 0.05em;
                        position: relative;
                    }
                }
            }
        }
    }
}

.login-links-container {
    display: flex;
    p {
        margin: 0;
        line-height: 31px;
    }
    a {
        font-weight: bold;
    }
}

.pmpro_series_all_posts_available_text {
    font-weight: bold;
}

.flex-card {
    @extend .card;
    @extend .no-hover;
    display: flex;
    padding: 1.5em;
    margin: 2em 0!important;
    border-radius: 6px;
    .photo-circle {
        margin-right: 1.25em;
        img {
            border-radius: 50%;
            max-width: 100px;
            height: auto;
            border: 2px solid $uhc-gold;
            filter: drop-shadow(1px 2px 10px transparentize($uhc-gold, 0.75));
        }
    }
    p {
        margin: 0;
        font-family: $iowan-old;
        color: $uhc-blue;
    }
    &.flex-align-center {
        align-items: center;
    }
    @media (max-width: 400px) {
        flex-direction: column;
        .photo-circle {
            margin: 0;
            margin-bottom: 1.25em;
        }
    }
}

#pmpro_account-links {
    ul {
        @include StyledList;
        margin-top: 1em;
        font-size: 1.25em;
        li {
            &:before {
                font-size: 18px;
                top: 5px;
            }
        }
    }
}

table.gsurvey-likert {
    margin-top: 1em;
    margin-bottom: 1.5em;
    th.gsurvey-likert-choice-label {
        // background-color: transparentize($uhc-blue, 0)!important;
        color: black;
        line-height: 1;
        font-size: 0.8em;
        &:nth-of-type(1) {
            background-color: darken(rgb(235, 72, 65), 2%);
        }
        &:nth-of-type(2) {
            background-color: darken(rgb(244, 136, 71), 4%);
        }
        &:nth-of-type(3) {
            background-color: darken(rgb(255, 200, 74), 4%);
        }
        &:nth-of-type(4) {
            background-color: darken(rgb(166, 195, 76), 4%);
        }
        &:nth-of-type(5) {
            background-color: darken(rgb(78, 192, 78), 2%);
        }
    }
    td.gsurvey-likert-choice {
        height: 2.5em!important;
        background-size: 20px 20px!important;
        &.gsurvey-likert-selected {
            background-color: transparentize($uhc-gold, 0.75)!important;
            &.gsurvey-likert-focus {
                background-color: transparentize($uhc-gold, 0.75)!important;
            }
        }
    }
    td:after {
        margin-left: 3rem!important;
    }
}

.pmpro_checkout_gateway-donor_perfect {
    #pmpro_form {
        // h3 {
        //     position: relative;
        //     span {
        //         z-index: 3;
        //         position: relative;
        //     }
        //     &:before {
        //         background-color: $uhc-gold;
        //         content: '';
        //         position: absolute;
        //         right: 0;
        //         left: -0.6em;
        //         height: 2em;
        //         top: -0.25em;
        //         width: 0.8em;
        //         opacity: 1;
        //         z-index: 1;
        //         transform: skewX( 146.25deg );
        //     }
        // }
        #pmpro_pricing_fields {
            #pmpro_level_cost {
                margin: 0;
            }
            .levellist {
                margin-top: 0;
                margin-bottom: 0;
            }
            & > .pmpro_checkout-fields {
                flex-direction: column;
                display: flex;
            }
        }
        #pmpro_user_fields {
            margin-bottom: 1em;
            .pmpro_checkout-fields {
                display: flex;
                flex-wrap: wrap;
                & > .pmpro_checkout-field {
                    flex-basis: 50%;
                    padding-right: 1em;
                }
            }
        }
        #pmpro_checkout_box-checkout_boxes {
            margin-bottom: 1em;
        }
        #pmpro_billing_address_fields {
            .pmpro_checkout-fields {
                display: flex;
                flex-wrap: wrap;
                & > .pmpro_checkout-field {
                    flex-basis: 100%;
                    margin-right: 2%;
                }
                & > .pmpro_checkout-field-bfirstname, & > .pmpro_checkout-field-blastname {
                    flex-basis: 48%;
                    margin-right: 2%;
                    display: inline-flex;
                    flex-direction: column;
                }
                & > .pmpro_checkout-field-baddress1, & > .pmpro_checkout-field-baddress2 {
                    flex-basis: 48%;
                    margin-right: 2%;
                    display: inline-flex;
                    flex-direction: column;
                }
                & > .pmpro_checkout-field-bcity {
                    flex-basis: 40%;
                    margin-right: 2%;
                    display: inline-flex;
                    flex-direction: column;
                }
                & > .pmpro_checkout-field-bstate {
                    flex-basis: 10%;
                    margin-right: 2%;
                    display: inline-flex;
                    flex-direction: column;
                }
                & > .pmpro_checkout-field-bzipcode {
                    flex-basis: 15%;
                    margin-right: 2%;
                    display: inline-flex;
                    flex-direction: column;
                }
                & > .pmpro_checkout-field-bcountry {
                    flex-basis: 27%;
                    margin-right: 2%;
                    display: inline-flex;
                    flex-direction: column;
                    select {
                        @extend usa-select!optional;
                    }
                }
                & > .pmpro_checkout-field-bphone {
                    flex-basis: 25%;
                    margin-right: 2%;
                    display: inline-flex;
                    flex-direction: column;
                }
                & > .pmpro_checkout-field-bemail, & > .pmpro_checkout-field-bconfirmemail {
                    flex-basis: 34.5%;
                    margin-right: 2%;
                    display: inline-flex;
                    flex-direction: column;
                }
            }
        }
        #pmpro_payment_information_fields {
            .pmpro_checkout-fields {
                display: flex;
                flex-wrap: wrap;
                & > .pmpro_payment-account-number {
                    flex-basis: auto;
                    margin-right: 2%;
                    display: inline-flex;
                    flex-direction: column;
                }
                & > .pmpro_payment-expiration {
                    flex-basis: 30%;
                    margin-right: 2%;
                    display: inline-flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    align-items: center;
                    label {
                        flex-basis: 100%;
                    }
                    select {
                        flex-basis: 34%;
                        margin-right: 3%;
                        &:nth-of-type(2) {
                            margin-left: 3%;
                            flex-basis: 54%;
                        }
                    }
                }
                & > .pmpro_payment-cvv {
                    flex-basis: 20%;
                    margin-right: 2%;
                }
            }
        }
        .pmpro_payment-discount-code, #other_discount_code_tr {
            flex-basis: 100%;
            label {
                color: $uhc-blue;
            }
            & > input#discount_code, & > input#other_discount_code {
                max-width: 12rem;
            }
            & > input#discount_code_button, & > input#other_discount_code_button {
                max-width: 8rem;
                text-align: left;
                color: $uhc-blue;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        @media (max-width: 768px) {
            input[type=text].input:not(#discount_code):not(#discount_code_button) {
                width: 100%;
            }
            .pmpro_checkout-fields {
                flex-direction: column;
            }
        }
    }
}

// Debugging

table.xdebug-error.xe-notice {
    overflow-y: scroll;
    height: 250px;
    display: block;
    transform: scale(0.9);
}