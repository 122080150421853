/*!
Theme Name: UHC USWDS
Theme URI: https://blaineturner.com
Author: BlaineTurner Advertising
Author URI:  http://blaineturner.com/
Description: US Web Design Standards WP Theme
Version: 20180828
*/
@import "variables";
@import "animate";
@import "grid";
@import "spinner";
@import "./node_modules/uswds/src/stylesheets/uswds";


// Functions
$browser-context: 17; // Default

@media (max-width: 768px) {
    body {
        font-size: 16px!important;
    }
}

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}


// global theme mixins
@mixin BeforeSlash( $color: $uhc-gold ) {
    position: relative;
    padding-left: 90px;
    &:before {
        content: ' ';
        position: absolute;
        width: 30px;
        height: 60px;
        left: 30px;
        top: 50%;
        transform: translateY(-50%) skew(-33.75deg);
        background: $color;
    }
}
.gold-slash {
    @include BeforeSlash();
    color: $uhc-blue;
    line-height: 1;
}
.blue-slash {
    @include BeforeSlash($uhc-blue);
}
.white-slash {
    @include BeforeSlash(#fff);
}
@mixin StyledList( $bulletcolor: $uhc-gold, $bulletcontent: '\f0c8' ) {
    list-style: none;
    list-style-position: outside;
    margin-top: 0;
    padding-left: 1rem;
    li {
        line-height: 1.2;
        padding-bottom: 12px;
        position: relative;
        padding-left: 18px;
        border-bottom: 1px solid #d8d8d8;
        &:before {
            position: absolute;
            font-family: FontAwesome;
            left: -1rem;
            font-size: 16px;
            top: 2px;
            content: $bulletcontent;
            color: $bulletcolor;
        }
    }
}
ul.styled {
    @include StyledList;
}
// site fonts
@import "fonts";
// typography
h1, h2, h3, h4, h5 {
	margin-top: 0;
}
.grey-text {
    color: #757575;
}
.text-center {
    text-align: center;
}
.text-larger {
    font-size: 1.5em;
}
@mixin line-break ( $color ) {
    width: 110px;
    background-color: $color;
    height: 3px;
    margin-bottom: 45px;
}
.blue-line {
    @include line-break($uhc-blue);
}
.gold-line {
    @include line-break($uhc-gold);
}
// list styles
.no-bullet {
    list-style-type: none;
    padding-left: 0;
}
.divider {
    @extend .no-bullet;
    li {
        border-bottom: 1px solid #ececec;
        padding-bottom: 1rem;
    }
}
// header
.usa-header-basic {
	border-bottom: none;
	background-color: #fff;
	.usa-nav-container {
		max-width: 100%;
		height: $navbar-height;
	}
	.usa-navbar {
		height: $navbar-height;
		padding-left: 10px;
		padding-right: 10px;
		@media (max-width: #{$nav-width - 1}) {
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
	        border-bottom: none;
		}
		.usa-logo {
			margin-top: 0px;
			margin-bottom: 0px;
			margin-left: 0px;
			width: 360px;
			@media (max-width: #{$nav-width - 1}) {
				max-width: calc(100% - 60px);
			}
			img {
				vertical-align: middle;
			    width: 100%;
			    height: auto;
			}
		}
	}
}
// navbar
nav.usa-nav {
	padding-top: 0px;
	padding-left: 0px;
	.usa-nav-inner {
		padding-left: 0;
		padding-right: 0;
	}
	.usa-nav-close {
		color: $color-primary;
		margin-bottom: 3em;
	}
	a {
		font-family: 'Helvetica Neue' !important;
		i.fa {
		    vertical-align: middle;
		}
	}
	@media (min-width: $nav-width) {
		height: $navbar-height;
		display:flex;
		align-items: center;
		.usa-nav-primary {
			margin-top: 0;
			display: flex;
		    flex: 1;
		    // align-items: center;
            align-items: stretch;
			li {
				position: relative;
			    transform: skew(-33.75deg, 0deg);
			    border-right: 1px solid #859cb3;
			    -webkit-font-smoothing: antialiased;
	    		transition: all 0.2s;
                display: flex;
                align-items: center;
                justify-content: center;
	    		&:last-child {
	    		    border-right: none !important;
	    		}
	    		&:hover {
	    			background-color: $uhc-gold;
	    		}
			    a {
			    	transition: background-color .3s;
				    font-size: 16px;
				    color: $uhc-blue;
				    display: flex;
                    align-items: center;
                    justify-content: center;
				    cursor: pointer;
				    padding: 16px 24px;
                    height: 100%;
                    span {
                        display: flex;
                        transform: skew(33.75deg, 0deg);
                        letter-spacing: -0.25px;
                    }
			        &:hover {
			            span {
	    			        border: none !important;
                            padding: 0!important;
			            }
	    			}
			    }
                &.login {
                    background-color: $uhc-blue;
                    a {
                        color: white;
                    }
                    &:hover {
                        background-color: lighten($uhc-blue, 8%);
                    }
                }
			}
			li.phone-number {
			    border-right: none;
			    &:hover {
	                background-color: rgba(0,0,0,.05);
		        }
			    a {
			        position: relative;
			        padding-left: 77px;
			        font-size: 38px;
                    display: flex;
                    align-items: center;
                    transform: skew(33.75deg, 0deg);
                    span {
                        transform: skew(0deg, 0deg);
                    }
			        &:before {
			            content: ' ';
			            width: 45px;
			            height: 45px;
			            background-image: url('img/call-icon-gold.svg');
			            position: absolute;
                        background-size: 100%;
                        left: 16px;
                        top: 50%;
                        transform: translateY(-50%);
			        }
			    }
			}
		}
	}
}
// mobile menu
.usa-overlay.is-visible {
    opacity: 0.5;
}
.usa-nav.is-visible {
    z-index: 10001;
    background-color: $uhc-blue;
    padding-right: 0px;
    display: block;
    border-left: none;
    @media (max-width: #{$nav-width - 1}) {
        a {
            color: #fff !important;
            font-weight: bold;
            text-decoration: underline;
            padding: 12px 0px 12px 25px;
            border-bottom: 1px solid #0033A0;
        }
        .usa-nav-primary > li {
            border-top: none !important;
        }
    }
}
// mobile menu toggle button
.usa-menu-btn, .usa-nav-close {
	background-color: #edaa1e;
    border-radius: 100%;
    box-shadow: $box-shadow-button;
    color: #FFF !important;
    max-width: 4rem;
}
.usa-nav-close {
	width: 4rem;
    height: 4rem;
	margin-top: 1rem;
    margin-right: 10px;
}
.fixedmobile{
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: #edaa1e;
    border-radius: 100%;
    z-index: 10005;
}
// footer
footer.page-footer {
    padding-top:0;
    background-color: $uhc-blue;
    margin-top: 20px;
    .footer-copyright{
        height:auto!important;
        line-height: 1.3;
        background-image: url(img/pattern-grid.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
        &::before {
            border: 3px solid $uhc-blue;
            width: 100%;
            content: "";
            position: absolute;
            top: 0.8em;
        }
        & > .container > .row {
            padding: 8% 0 0;
            @media (min-width: 600px) {
                padding: 2% 0 0;
            }
        }
        .footer-white{
            background-image: url(img/gold-pattern.svg);
            background-position: top center;
            background-size: 1900px;
            background-repeat: repeat-x;
            background-color: white;
            & > .container {
                padding-top: 30px;
                padding-bottom: 10px;
            }
            @media (max-width: $breakpoint-ipad) {
                background-image:none;
                background-color:#fff;
            }
            nav ul li{
                @media (max-width: $breakpoint-ipad) {
                    background-image:none;
                    background-color:#fff;
                }
                @media (max-width: $breakpoint-small-tablet) {
                    border-right:none;
                    width: 100%;
                }
            }
            div.m7{
                padding-top:6px;
                padding-left:20px;
                @media (max-width: $breakpoint-small-tablet) {
                        padding-left:0;
                }
            }
        }
        h5, p, ul, ol, div {
    	    color: #fff;
    	}
    	h5 {
    	    font-size: 24px;
    	    margin-bottom: 0px;
    	    font-family: 'Helvetica Neue Condensed', Helvetica, sans-serif;
    	}
    	a {
            color: $uhc-gold;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            &:visited {
                color: $uhc-yellow-green;
            }
        }
        .footer-column {
        	line-height: 1.5;
        	@media (min-width: $large-screen) {
        	    padding-right: 20px;
        	}
            ul {
        	    list-style-type: none;
        	    padding-left: 0;
            }
        }
    }
    img.logo {
        width: 100%;
        height: auto;
        max-width: 350px;
    }
    input[type="search"] {
        border: 1px solid $uhc-gold;
    }
    .calling-card {
    	@extend .card;
    	font-size: 38px;
	    color: $uhc-gold !important;
	    background-color: rgba(245, 245, 255, 0.1)!important;
	    margin-top: 4%;
	    display: flex;
	    width: 340px;
	    height: 90px;
	    max-width: 100%;
	    align-items: center;
	    justify-content: center;
	    text-decoration: none;
	    font-weight: bold;
	    border-radius: 6px;
	    &:hover {
	        text-decoration: none!important;
	        background-color: white!important;
	    }
	    &:visited {
	        color: #c0a83f !important;
	    }
	    img {
	    	max-width: 45px;
            width: 100%;
    		margin-right: 12px;
	    }
	    @media (max-width: $small-screen) {
	        font-size: 28px;
	        img {
	            max-width: 32px;
	        }
	    }
    }
    @media #{$medium-and-down} {
        input[type="text"] {
            max-width: 320px !important;
        }
    }
}
#footer .m4 > .footer-column:nth-of-type(2) p {
    margin-bottom: 0.25em;
}
// sidebar
aside {
    background-color: #fff;
    position: relative;
    padding-bottom: 1%;
    margin-top: 1em;
    border-radius: 6px !important;
    @media (min-width: 1200px) {
        margin-top: -230px;
    }
    @media (max-width: $medium-screen) {
        top: 0;
        border: none;
    }
	.sidebar-widget {
		ul {
			list-style-type: none;
		}
	}
	.sidebar-card {
	    @extend .card;
	    max-width: 433px !important;
        position: relative;
        margin-top: 0 !important;
        //padding-top: 105px;
        //padding-bottom: 230px;
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 6px !important;
        border: none!important;
        transform: none!important;
        @media (max-width: #{$large-screen - 1}) {
            margin-top: 100px !important;
            margin-right: auto !important;
            margin-left: auto !important;
        }
        // @media (max-width: $small-screen) {
        //     padding-top: 20%;
        // }
        img.card-image-full {
            position: absolute;
            top: -105px;
            width: 100%;
            max-width: calc(100% + 4px) !important;
        }
        .textwidget {
            & > div {
                padding: 6px 32px;
            }
            .lead {
                font-family: 'Helvetica Neue', Helvetica, sans-serif;
                font-weight: bold;
                font-size: 24px;
                line-height: 1.2;
                color: #757575;
            }
            ul {
                @include StyledList( $uhc-gold );
                font-family: 'Helvetica Neue', Helvetica, sans-serif;
                font-size: 20px;
                color: #000;
            }
        }
        .healthgrade-logo {
            width: 298px;
            display: block;
            margin: 0 auto 18px auto;
        }
        .healthgrade-stars {
            background-color: #687385;
            border-radius: 3px;
            padding: 10px 20px;
            text-align: center;
            color: #fff;
            font-size: 32px;
            max-width: 298px;
            margin: 0 auto;
            box-shadow: $box-shadow;
            transition:.25s all ease-in-out;
            &:hover{
                box-shadow: 0 20px 30px rgba(0,0,0,.1);
                transform: translate(-2px,-2px);
            }
        }
        a.hg-link{
            text-decoration:none;
        }
        .sidebar-blue-text {
            text-align: center;
            font-size: 32px;
            font-family: 'Helvetica Neue Condensed', Helvetica, sans-serif;
            color: #1162a9;
            text-transform: uppercase;
            margin-bottom: 8px;
        }
	    .sidebar-card-title, .sidebar-card-content {
	        padding: 7.5%;
	    }
	    .sidebar-card-title {
	        background: #f0b220;
	        font-size: 18px;
	    }
	    .sidebar-card-content {
	        background: #fff;
	        ul {
	            @include StyledList;
	        }
	    }
	}
	iframe {
        max-width: 100%;
    }
	// widget title
	h5 {
        font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
        color: $uhc-blue;
        padding: 16px 0 24px;
        border-bottom: 1px solid #a8b4cc;
        margin-bottom: 30px;
        font-size: 21px;
        line-height: 1.25;
        span {
            font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
            font-weight: bold;
            display:block;
            text-transform: uppercase;
            font-size:27px;
        }
    }
	.sb-links {
        hr{
            margin: 30px 0;
            border-top: 1px solid #a8b4cc;
        }
        ul {
            margin:0;
            padding:0;
            li{
                list-style:none;
                transition:.25s all ease-in-out;
                background-color:#000;
                &:hover{
                    -webkit-box-shadow: 0 20px 30px rgba(0,0,0,.1);
                    box-shadow: 0 20px 30px rgba(0,0,0,.1);
                    -webkit-transform: translate(-2px,-2px);
                    transform: translate(-2px,-2px);
                }
               .oncology {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvcancercenter.com/wp-content/uploads/2017/02/UHC-site-wvu-version-web-export-wo-text-sliced_02.jpg);
                }
                .ortho {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvorthocenter.com/wp-content/uploads/2017/04/ortho-page-header-images3.jpg);
                }
                .emergency {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://uhcemergencyroom.com/wp-content/uploads/2017/08/uhc-er-header3c.jpg);
                    background-position:bottom;
                }
                a {
                    padding:8% 5%;
                    text-decoration:none;
                    display:block;
                    border: 1px solid #16375c;
                    font-family: 'Iowan Old Style Bold';
                    font-size:35px;
                    text-transform:uppercase;
                    line-height: 1.2;
                    background-size: 100%;
                    color: #fff;
                    text-shadow: 1px 1px 0 #18385d;
                    background-blend-mode:multiply;
                    span{
                        font-weight:300;
                        font-size:13px;
                        display:block;
                        letter-spacing: 3px;
                        font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
                    }
                    @media (max-width: $small-screen) {
                        font-size: 28px;
                        word-break: break-word;
                    }
                }
            }
        }
    }
    .card {
        @media (max-width: #{ $nav-width - 1 }) {
            border: none;
            &:hover {
                box-shadow: none;
            }
        }
    }
    .link-wrap {
        position:relative;
        display: block;
        width: 100%;
        iframe {
            z-index: 2;
        }
        img {
            display: block;
            margin: 0 auto;
            box-shadow: $box-shadow-large;
        }
    }
    .blocker {
        position:absolute;
        height:100%;
        width:100%;
        z-index:1;
        //background:rgba(255,0,0,0.5);
    }
// end aside
}
aside.post-sidebar {
    padding-top: 1%;
    padding-left:1%;
    padding-right: 1%;
    border:1px solid #a8b4cc;
    @media (max-width: #{ $nav-width - 1 }) {
        padding: 0;
        border: none;
    }
}
// section
.usa-section {
	padding-top: 50px;
	padding-bottom: 50px;
}
// blogs section
.section-blogs {
    @extend .grey-bg;
}
// blog archive
body.blog{
    .ind-blog {
        @media (min-width: $medium-screen) {
            padding-right:3.5%;
        }
        .card{
            margin: .5rem 2% 2rem 0;
        }
    }
    .sb-links{
        border:none;
    }
}
// single template
.single, .search {
    article {
        padding-right: 3.5%;
        padding-bottom: 50px;
        @media (max-width: 700px) {
            padding-right: 2%;
        }
        p strong {
            font-family: 'Helvetica Neue', Helvetica, sans-serif;
            font-weight: bold;
        }
    	// lists
    	ul {
    		@include StyledList;
    	}
        h2 {
            font-family: 'Helvetica Neue', Helvetica, sans-serif;
            font-weight: bold;
            letter-spacing: 0px;
            text-transform: none;
            font-size: 32px;
            line-height: 38px;
        }
        ol {
            padding-left: 40px;
            counter-reset: list;
            li {
                font-weight: bold;
                list-style: none;
                position: relative;
                &:before {
                    counter-increment: list;
                    content: counter(list) ") ";
                    position: absolute;
                    left: -40px;
                    top: -4px;
                    font-size: 32px;
                    font-family: 'Iowan Old Style Bold';
                }
                h2 {
                    font-family: 'Helvetica Neue', Helvetica, sans-serif !important;
                font-weight: bold;}
                p {
                    font-size: 17px;
                    font-family: 'Helvetica Neue';
                }
            }
        }
        .card:not(.blog-video-card) {
            padding: 32px;
            margin-top: 64px;
            margin-bottom: 64px;
            font-family: "Iowan Old Style Bold", Helvetica, sans-serif;
            color: $uhc-blue;
            font-size: 24px;
            box-shadow: $box-shadow-large;
            &:hover {
                transform: none !important;
            }
        }
    }
}
// post meta
.post-meta {
	font-size: 0.8em;
	text-transform: uppercase;
}
// pagination
ul.pagination {
	list-style-type: none;
	li {
		display: inline-block;
		margin-right: 10px;
	}
}
body.single{
    .page-heading{
        background-color: rgba(0, 92, 181, 0);
    }
}
// page styles
.page-heading {
    padding: 7% 0 8%;
    position: relative;
    background: rgba(0,92,181,.22);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .container {
    	position: relative;
    	z-index: 1;
    }
    h1, h2, p {
    	color: #fff;
	    max-width: 767px;
	    text-shadow: 1px 1px 4px #000;
	    strong {
		    font-size: 1.5em;
		    font-weight: 800;
		}
    }
    p {
    	color: #fff;
	    margin: 0 0 8px 0 !important;
	    text-shadow: 1px 1px 3px #000;
	    font-size: 22px;
    }
	a {
		text-shadow: none;
	}
	.usa-button {
	    font-size: 22px;
	}
    @media (max-width: 1400px) {
        &.hero-swiper {
            .swiper-button-white {
                display: none;
            }
        }
    }
    @media (min-width: 1400px) {
        &.hero-swiper {
            .swiper-slide .container {
                padding-left: 50px;
                padding-right: 50px;
            }
        }
    }
}
.blog-heading {
    background-position: center;
    position: relative;
    overflow: hidden;
    padding: 12.5% 0;
    .blog-titlebg {
        position: absolute;
        background-color: rgba(104, 117, 99, 0.62);
        height: 1500px;
        width: 1250px;
        top: -106%;
        transform: rotate(33.75deg);
        left: -7%;
        @media (max-width: $large-screen) {
            left: -24%;
        }
    }
}
// diagonal / angle div
.angle-post {
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    @media (max-width: 800px) {
        display: none;
    }
    svg {
        height: 200px;
        height: calc(25px + 10vw);
        width: 100%;
        z-index: 1;
    }
}
// TEXT STYLES
// typography
body {
    font-family: 'Helvetica Neue', helvetica,arial,sans-serif!important;
    font-size: 17px;
    line-height: 31px;
    color: $uhc-black;
}
h1.alt {
    font-family: 'Helvetica Neue Condensed',helvetica,arial,sans-serif;
    text-transform: uppercase;
    font-size: 44px;
    line-height: 55px;
    background-color: $uhc-black;
    color: $uhc-white;
}
h1 {
    font-family: 'Helvetica Neue Condensed',helvetica,arial,sans-serif;
    text-transform: uppercase;
    font-size: 58px;
    line-height: 1;
    color: $uhc-blue;
    letter-spacing:-2px;
      @media (max-width: $breakpoint-phone) {
        font-size: 38px;
        line-height: 1;
        letter-spacing: 0px;
        }
}
h2 {
    font-family: 'Helvetica Neue Condensed',helvetica,arial,sans-serif;
    text-transform: uppercase;
    font-size: 55px;
    color: #000;
    letter-spacing:-2px;
    @media (max-width: $breakpoint-phone) {
        font-size:34px;
    }
}
h3 {
    font-family: 'Helvetica Neue',Helvetica,sans-serif;
    font-weight: bold;
    font-size: 30px!important;
    line-height: 1.2;
    color: #757575;
    @media (max-width: $breakpoint-phone) {
        font-size: 24px;
        line-height: 1;
        letter-spacing: 0px;
    }
}
h4 {
    font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
    font-size: 26px;
    line-height: 37px;
    color: $uhc-black;
}
h5 {
    font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
    font-size: 22px;
    line-height: 34px;
    color: $uhc-black;
}
// intro paragraph
.intro-p {
    font-size: 28px;
    line-height: 1.5;
    color: $uhc-blue;
    width: 100%;
    @media (max-width: $small-screen) {
        font-size: 18px;
    }
}
// main
.blog main {
    padding-bottom: 50px;
}
// button
a.usa-button, button.usa-button, input[type="submit"].usa-button {
    font-family: 'Helvetica Neue' !important;
    font-size: 17px;
    color: #fff;
    background-color: $uhc-gold;
    transition: all .2s;
    width: auto;
    &:hover {
        background-color: $uhc-yellow-green;
    }
    &:active {
        background-color: #e8d27c;
    }
}
.usa-button-flat {
    @extend .usa-button;
    border: 2px solid #fff;
    font-family:  'Helvetica Neue',helvetica,arial,sans-serif;
    box-shadow: none;
    background: transparent !important;
    color: #343434;
    cursor: pointer;
    &:hover {
        border: 2px solid #fff;
        background: rgba(0,0,0,.1) !important;
        box-shadow: none;
    }
}
// elements and components
.card {
    border: 1px solid #ececec;
    transition: .25s all ease-in-out;
    position: relative;
    margin: .5rem 0 1rem;
    background-color: #fff;
    max-width: 100%;
    .title {
        font-family: 'Helvetica Neue', Helvetica, sans-serif;
        font-weight: bold;
        padding: #{$card-padding / 4} #{$card-padding * 2};
        background: $uhc-gold;
        color: #fff;
        max-width: 100%;
    }
    .content {
		padding: $card-padding #{$card-padding * 2};
		max-width: 100%;
		p:first-child {
		    margin-top: 0;
		}
	}
	.usa-button, .usa-button-flat {
	    display: inline-block;
        color: #fff;
        text-align: center;
        padding: 6px 25px;
	}
    &:hover {
    	box-shadow: $box-shadow-large;
    	transform: translate(-2px, -2px);
    }
}
.card.simple {
    padding: $card-padding;
    margin-top: 32px !important;
    margin-bottom: 32px !important;
}
.card.no-hover {
    box-shadow: $box-shadow-large;
	transform: translate(-2px, -2px);
    &:hover {
    	box-shadow: $box-shadow-large !important;
    }
}
.card.callout {
    padding: $card-padding;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    .card-icon {
        max-width: 120px;
        margin-right: 16px;
        float: left;
    }
    @media (max-width: $small-screen) {
        flex-wrap: wrap;
        display: block;
        padding-top: 64px !important;
        padding-bottom: 16px !important;
        .card-icon {
            float: none;
            position: absolute;
            top: -60px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
// blog tease
a.tease {
	text-decoration: none !important;
	width: 100%;
    border-radius: 6px;
	@media (max-width: $small-screen) {
	    min-height: 0px;
	    height: auto;
	    margin: 0 auto;
	    .content {
    	    font-size: 18px;
    	}
	}
	@media (min-width: 992px) {
	    min-height: 320px;
	}
	img {
        margin: -1px;
        max-width: calc(100% + 2px);
        border-radius: 6px;
    }
    .featured-img {
        border-radius: 6px 6px 0 0;
        width: 100%;
        max-width: calc(100% + 2px);
        height: 160px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .featured-img-link {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 100%;
        }
    }
	&:hover {
		text-decoration: underline !important;
	}
	.content {
	    font-size: 24px;
	}
}
// doctor cards
#specialists {
    margin-top: 2em;
    & > .col {
        max-width: 550px;
        @media (max-width: 845px) and (max-width: 992px) {
            margin: 0 auto;
        }
    }
}
.doc-card {
    display: flex;
    flex: 1;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-radius: 6px;
    img.doc-headshot {
    	height: 100%;
        padding: 0.5em;
        display: inline-block;
        float: left;
        max-width: 120px;
        width: 100%;
    }
    .doc-blue {
        background-color: rgba(207, 233, 244, 0.6);
        width: 100%;
        height: 115px;
        position: relative;
        border-radius: 5px 5px 0 0;
	    h5 {
            display: inline-block;
            width: 100%;
            line-height: 1.3;
            margin: auto;
            position: absolute;
            font-size: 21px;
            font-family: 'Helvetica Neue';
            color: $uhc-blue;
            bottom: 0;
            margin: 0;
            @media (min-width: 600px) {
                font-size: 23px;
            }
            span {
                font-size: 0.6em;
                font-weight: 600;
                color: $uhc-blue;
            }
		}
	}
	.bottom-doc {
	    max-width: 100%;
	    padding: 32px;
	    p:first-child {
    	    margin-top: 0px;
        }
        p {
            margin-bottom: 0;
            font-size: 16px;
        }
	}
	a.card-button {
        width: 90%;
        text-align: center;
        padding: 0.5em 0;
        background-color: $uhc-gold;
        text-decoration: none;
        transition: 0.2s all;
        color: white;
        border-radius: 6px;
        margin: 0 auto;
        margin-bottom: 1em;
        font-weight: 700;
        &:hover {
            opacity: 0.9;
            transition: 0.2s all;
        }
	}
}
// sections and background
.grey-bg {
    background-color: #e8eaec;
    padding-bottom: 5%;
}
/// map style
.map-container {
    .infowindow {
        position: absolute;
        display: none;
        top: 50%;
        width: 474px;
        left: 50%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 2;
        margin-left: -601.5px;
        margin-top: -200px;
        padding: 1.5em;
        font-weight: 300;
        font-size: 24px;
        border-radius: 6px;
        .infowindow-margin {
            p:nth-of-type(2) {
                margin: 0!important;
                & > a {
                    margin: 0;
                }
            }
        }
        img {
            height: 46px;
            @media (min-width: 500px) {
                height: 56px;
            }
        }
        .usa-button {
            width: 100%;
            text-align: center;
            padding: 1em 0;
            background-color: $uhc-gold;
            text-decoration: none;
            transition: $global-transition;
            color: $uhc-blue;
            border-radius: 6px;
            margin: 0 auto;
            margin-bottom: 0;
            font-weight: 700;
            display: block;
            &:hover {
                opacity: 0.9;
                transition: $global-transition;
                background-color: $uhc-gold;
                color: $uhc-blue;
            }
        }
        @media (max-width: 1270px) {
            top: 18%;
            left: 10%;
            margin-left: 0;
            margin-top: 0;
            width: 474px;
        }
        @media (max-width: $small-screen) {
            display: none!important;
        }
        @media (min-width: 1320px) {
            left: 45%;
        }
    }
    #map {
        height: 400px;
        position: relative;
        z-index: 1;
        background-image: url('/wp-content/uploads/2021/04/uhc-map-blurred.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        #showMap {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: $helvetica-condensed!important;
            font-size: 1.2em;
            padding: 0.75em 2em;
            max-width: 250px;
        }
    }
    &.activated {
        #map {
            height: 600px;
        }
        .infowindow {
            display: block;
        }
    }
}
// #map {
//     height: 600px;
//     position: relative;
//     z-index: 1;
//     @media (max-width: $small-screen) {
//     	display: none;
//     }
// }
// .infowindow {
//     position: absolute;
//     top: 50%;
//     width: 480px;
//     left: 50%;
//     background-color: rgba(255, 255, 255, 0.8);
//     z-index: 2;
//     margin-left: -601.5px;
//     margin-top: -200px;
//     padding: 1.5em;
//     color: #6d665c;
//     font-weight: 300;
//     font-size: 24px;
//     @media (max-width: 1270px) {
//     	display: none;
//     }
//     @media (min-width: 1400px) {
//         left: 45%;
//     }
//     .infowindow-margin {
//         p:last-of-type {
//             margin-bottom: 0;
//         }
//     }
//     .usa-button {
//         width: 100%;
//         text-align: center;
//         padding: 1em 0;
//         background-color: $uhc-gold;
//         text-decoration: none;
//         -webkit-transition: .2s all;
//         transition: .2s all;
//         color: $uhc-blue;
//         border-radius: 6px;
//         margin: 0 auto 1em;
//         font-weight: 700;
//         margin-bottom: 0;
//     }
// }
// single doctors
.single-doctors {
    .post-type-doctors {
        margin-top: 32px;
        padding-bottom: 50px;
        .row .m7 {
            h1 {
                margin-bottom: 0;
            }
            & > p:first-of-type {
                margin: 0;
                font-weight: 600;
                color: $uhc-blue;
                font-size: 1.2em;
            }
        }
        .row .col:first-of-type div div {
            @media (min-width: 992px) {
                .doc-svg {
                    bottom: 10px!important;
                }
            }
        }
    }
}
// utility classes
.padding-5-0 {
    padding-top: 5%;
    padding-bottom: 5%;
}
.no-mobile {
    @media (max-width: #{$medium-screen - 1}) {
        display: none;
    }
}
.yes-mobile {
    display: none !important;
    @media (max-width: #{$medium-screen - 1}) {
        display: block !important;
    }
}
.yes-mobile-inline {
    display: none !important;
    @media (max-width: #{$medium-screen - 1}) {
        display: inline !important;
    }
}
.yes-mobile-flex {
    display: none !important;
    @media (max-width: #{$medium-screen - 1}) {
        display: flex !important;
    }
}
.mobile-block-center {
    @media (max-width: #{$medium-screen - 1}) {
        display: block;
        margin: 0 auto;
    }
}
.mobile-nav-link {
    display: none !important;
    @media (max-width: #{$nav-width - 1 }) {
        display: block !important;
    }
}
.negativemargtop {
    @media (min-width: $large-screen) {
        margin-top: -243px;
    }
}
.float-right-no-mobile {
    float: right;
    @media (max-width: $medium-screen) {
        float: none;
    }
}
.float-left-no-mobile {
    float: left;
    @media (max-width: $medium-screen) {
        float: none;
    }
}
// margin classes
.margin-left-no-mobile {
    margin-left: 16px;
    @media (max-width: $medium-screen) {
        margin-left: 0;
    }
}
.margin-right-no-mobile {
    margin-right: 16px;
    @media (max-width: $medium-screen) {
        margin-right: 0;
    }
}
.flex-end-self {
    align-self: flex-end;
}
.et_monarch .et_social_networks ul.et_social_icons_container {
    display: flex !important;
    align-items: center;
    @media (max-width: $small-screen) {
        justify-content: space-between;
    }
    li {
        width: auto !important;
    }
}
// top bar
#topbar {
    background: linear-gradient(to right, #005eb8 0%, $uhc-blue 17%, $uhc-blue 95%, $uhc-blue 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005eb8', endColorstr='#002855',GradientType=1 );
    position: relative;
    z-index: 1;
    .top-container {
        width: 90% !important;
        min-height: 75px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: 56px;
        color: #fff;
        font-size: 18px;
        line-height: 1;
        img {
            margin-right: 1rem;
        }
        .usa-button, .usa-button-flat {
            font-size: 16px;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
}
.uhc-bar {
    background-color: #17375c;
    text-align: right;
    background-image: url(img/pattern-grid.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
    @media (max-width: #{$nav-width -  1}) {
        display: none;
    }
    .usa-nav-container {
        height: auto;
        padding: 5px 55px;
    }
    a {
        color: #fff;
        font-size: 14px;
        text-decoration: none;
        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
}
.notch {
    width: 48px;
    height: 48px;
    transform: rotate(45deg);
    background: #fff;
    position: absolute;
    top: -24px;
    left: 0px;
    @media (max-width: $small-screen) {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
    }
}
// search field
.usa-search.usa-search-small {
    margin-bottom: 1rem;
    input[type="search"] {
        width: calc(100% - 45px);
        max-width: 100%;
        height: 35px;
    }
    [type="submit"] {
        background-color: $uhc-gold;
        height: 35px;
    }
}
.usa-search {
    max-width: 18em;
}
// include custom site styles
@import "custom";
#bitnami-banner .bitnami-corner-image-div .bitnami-corner-image{
    display:none!important;
}
p.no-p{
    display:inline-block;
}
// new blog styles, and sidebar styles, to copy over past this point
.blogarchive {
    padding:11.5% 0 15%;
    position: relative;
    z-index: 9;
    h2 {
        color: #fff;
        max-width: 615px;
        margin-top: 0;
        margin-bottom: 10px;
    }
}
body.blog{
    .card.archive-card.grid-item:nth-of-type(1) {
    width: 100%!important;
    z-index: 9;
    .featured-img{
        height:275px;
        .featured-img-link{
            display:block;
            height:100%;
            width:100%
        }
    }
    h2{
        font-size:32px;
        font-family:'Helvetica Neue condensed',helvetica,arial,sans-serif;
        text-transform:uppercase;
        line-height: 1.1;
        a{
            color:$uhc-med-blue;
        }
    }
    &:before{
        content: 'Featured';
    /* width: 200px; */
    /* height: 43px; */
    background-color: #7670e1;
    color: #f1eee7;
    /* display: block; */
    position: absolute;
    padding: 2% 4%;
    left: 0;
    top: 0;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 4px;
    font-size:14px;
    }
    }
    .ind-blog{
        margin-top: -140px;
        h2{
        font-family:'Helvetica Neue ',helvetica,arial,sans-serif;
        text-transform:none;
        }
          @media #{$medium-and-down} {
           margin-top:0;
        }
    }
}
body.paged-2,body.paged-3,body.paged-4{
    .card.archive-card.grid-item:nth-of-type(1) {
    width: 31.333% !important;
     @media #{$medium-and-down} {
       width: 48% !important;
    }
    @media #{$small-and-down} {
       width: 100% !important;
    }
    .featured-img{
        height:160px;
        .featured-img-link {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
}
    }
    h2{
        font-size: 20px;
        font-family:'Helvetica Neue',helvetica,arial,sans-serif;
    }
     &:before{
        content: none;
     }
    }
    .ind-blog{
        margin-top: 0px;
    }
}
.flex-blog{
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap:wrap;
  -ms-flex-wrap:wrap;
    flex-wrap: wrap;
}
.ind-blog{
    img{
        max-width:100%;
    }
    .archive-card{
        margin-left: 0 !important;
        margin-right: 2%;
        width: 31.333%!important;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        @media #{$medium-and-down} {
            width: 48% !important;
        }
        @media #{$small-and-down} {
            width: 100% !important;
        }
        h2 {
            font-size: 20px;
            letter-spacing: 0;
            margin: 0 0 7px;
                a{
                    color:$uhc-blue;
                    text-decoration:none;
                    &:visited{
                        color:$uhc-blue;
                    }
                }
        }
        .readmore-archive{
            display:block;
            padding: 1% 3% 1% 5%;
            border-top:1px solid #e3e3e3;
            color: #545454;
            position: absolute;
            bottom: 0;
            width: 100%;
            text-decoration:none;
            font-family: 'Helvetica Neue', Helvetica, sans-serif;
            background-color: #f6f6f6;
            transition:.25s all ease-in-out;
            text-align: right;
            &:hover {
                opacity: 0.8;
                transition:.25s all ease-in-out;
            }
        }
    }
}
.featured-img{
        width: calc(100% + 2px);
    height: 160px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .featured-img-link {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
}
}
.sb-links {
        hr{
            margin: 30px 0;
            border-top: 1px solid #a8b4cc;
        }
        ul {
            margin:0;
            padding:0;
            li{
                list-style:none;
                transition:.25s all ease-in-out;
                background-color:#000;
                &:hover{
                    -webkit-box-shadow: 0 20px 30px rgba(0,0,0,.1);
                    box-shadow: 0 20px 30px rgba(0,0,0,.1);
                    -webkit-transform: translate(-2px,-2px);
                    transform: translate(-2px,-2px);
                }
               .oncology {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvcancercenter.com/wp-content/uploads/2017/02/UHC-site-wvu-version-web-export-wo-text-sliced_02.jpg);
                }
                .ortho {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvorthocenter.com/wp-content/uploads/2017/04/ortho-page-header-images3.jpg);
                }
                .emergency {
                    background-image:linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://uhcemergencyroom.com/wp-content/uploads/2017/08/uhc-er-header3c.jpg);
                    background-position:bottom;
                }
                a {
                    padding:8% 5%;
                    text-decoration:none;
                    display:block;
                    border: 1px solid #16375c;
                    font-family: 'Iowan Old Style Bold';
                    font-size:35px;
                    text-transform:uppercase;
                    line-height: 1.2;
                    background-size: 100%;
                    color: #fff;
                    text-shadow: 1px 1px 0 #18385d;
                    background-blend-mode:multiply;
                    span{
                        font-size:13px;
                        display:block;
                        letter-spacing: 3px;
                        font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
                    }
                    @media (max-width: $small-screen) {
                        font-size: 28px;
                        word-break: break-word;
                    }
                }
            }
        }
}
.post-sidebar h5 span {
    font-family: 'Helvetica Neue',helvetica,arial,sans-serif;
    font-weight: bold;
    display: block;
    text-transform: uppercase;
    font-size: 27px;
}
.mywvuchart-card {
    padding: 0!important;
    margin-top: 2em!important;
    border-radius: 6px;
    margin-bottom: 6em!important;
    @media (min-width: 600px) {
        margin-bottom: 2em!important;
    }
    .sidebar-widget.mywvuchart {
        padding: 0;
        display: block;
        a {
            display: block;
            background-color: $uhc-blue;
            color: white;
            text-decoration: none;
            font-weight: 700;
            text-align: center;
            font-size: 1.5em;
            padding: 0.5em 0;
            transition: 0.2s all;
            border-radius: 6px;
            &:hover {
                opacity: 0.9;
                transition: 0.2s all;
            }
            img {
                width: 50%;
                padding-top: 6px;
            }
        }
        .textwidget {
            padding: 0;
        }
        img {
            border-radius: 5px 5px 0 0;
            width: 100%;
        }
    }
    & > div:nth-of-type(2) {
        margin-top: -2em;
        background-image: none!important;
    }
    & > div:nth-of-type(3) {
        padding: 1em!important;
        & > div {
            border-radius: 6px;
            display: inline-block;
            width: 100%;
        }
    }
}
//Slideshow
ul#slides {
    position: relative;
    height: 7em;
    margin-bottom: 0;
    margin-top: 0;
    @media (min-width: 600px) {
        height: 6em;
    }
}
#slides .slide {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: fit-content;
    height: 5em;
    padding: 0 2em!important;
    font-size: 1em;
    line-height: 1.3!important;
    border-bottom: none!important;
    margin-top: 2.5em;
    color: $uhc-blue;
    background: #fff;
    @media (min-width: 600px) {
        height: 4em;
        font-size: 18px;
    }
    @media (min-width: 992px) {
        margin-top: 1.5em;
    }
    &:before {
        content: "";
        top: 0;
        left: 0;
    }
    &:nth-child(2) {
        opacity: 0;
        -webkit-animation-delay: 6s;
        animation-delay: 6s;
        -moz-animation-delay: 6s;
        -o-animation-delay: 6s;
    }
    &:nth-child(3) {
        opacity: 0;
        -webkit-animation-delay: 12s;
        animation-delay: 12s;
        -moz-animation-delay: 12s;
        -o-animation-delay: 12s;
    }
    &:nth-child(4) {
        opacity: 0;
        -webkit-animation-delay: 18s;
        animation-delay: 18s;
        -moz-animation-delay: 18s;
        -o-animation-delay: 18s;
    }
    & > div {
            width: 45px;
            height: 45px;
            float: left;
            margin-right: .5em;
            margin-bottom: 6em;
        .icon {
            height: 100%;
            width: fit-content;
        }
    }
}
.fade {
    -webkit-animation: fade 24s infinite;
    animation: fade 24s infinite;
    -moz-animation: fade 24s infinite;
    -o-animation: fade 24s infinite;
}
@-webkit-keyframes fade {
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
}
@-moz-keyframes fade {
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
}
@keyframes fade {
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
}
@-o-keyframes fade {
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
}
.article-body ul li ul {
	margin-top: 1em!important;
	margin-bottom: 0;
}
.article-body ul li ul li::before {
	content: "◻"!important;
}
ul li {
	line-height: 1.5!important;
	break-inside: avoid-column;
}
@media (max-width: 768px) {
	.intro-p {
		font-size: 18px!important;
	}
}
.card.callout {
	margin-top: 64px!important;
}
.section-blogs {
	margin-top: 2em;
}
.row.logos div {
	padding: 0.5em;
	max-height: 70px;
	margin-top: 1em;
}
.row.logos img {
	max-height: 70px;
	width: auto;
	align-self: center;
}
.row.logos div:first-of-type img {
	align-self: flex-end;
}
@media (max-width: 1200px) and (min-width: 992px) {
	.home .logos {
	margin: 0 11em;
}
}
.uronav {
    max-height: 31.2em;
    margin: 0 auto;
    display: block;
}
.text-col-2 li {
	display: flex;
}
@media (min-width: 500px) and (max-width: 992px) {
	.dr-contact {
	display: block;
	margin: 0 auto;
max-width: 75%;
margin-bottom: 1em;
}
}
@media (max-width: 1200px) {
	.uronav {
	margin-top: 0;
}
.page-heading .container p {
	line-height: 1.2;
}
}
@media (min-width: 992px) {
	.text-col-2 {
		-webkit-column-count: 2;
		column-count: 2;
		-webkit-column-gap: 40px;
		column-gap: 40px;
	}
}
.inline-image {
    margin: 2em auto 3em;
    box-shadow: 0px 20px 50px -15px rgba(0,0,0,.4);
    display:block;
}
.post-sidebar{
    position:relative;
    z-index: 500;
    .link-wrap{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 500;
    }
}
body.home{
    .page-heading{
        background-color:rgba(0,92,181,0);
        padding:10.5% 0;
    }
}
blockquote.card.callout {
    @media (max-width: 600px) {
        padding-top: 48px!important;
        padding-bottom: 24px!important;
    }
}
blockquote.card.callout img {
    max-width: 120px;
    margin: 0 auto;
    height: auto;
    display: block;
    margin-bottom: 1em;
    @media (min-width: 600px) {
        max-width: 120px;
        margin-right: 16px;
        float: left;
        height: auto;
        margin-bottom: 15em;
    }
    @media (min-width: 720px) {
        margin-bottom: 9em;
    }
}
.usa-nav.is-visible .usa-nav-link:hover {
    color: $uhc-blue!important;
    text-decoration: no-underline!important;
}
.body-cards .card {
    padding: 4%;
    margin: 1%;
    overflow: hidden;
    border-radius: 0 6px 6px 6px;
    width: 48%!important;
}
.content-area h2 {
    color: $uhc-blue;
    line-height: 0.95;
    font-weight: bold;
    margin: 30px 0 0 0;
}
b, strong {
    letter-spacing: 0.5px;
}
@media (max-width: 600px) {
.content-area .card {
    width: 100%!important;
    margin-bottom: 1em;
}
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     .map-container{
         display:none;
     }
}
@media print {
    body{
        background-color:#000!important;
        img{
            width:100%!important;
        }
        header{
            display:none!important;
        }
    }
}